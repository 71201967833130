import * as React from "react";

const Hero = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 1362.05 998.17",
    }}
    viewBox="0 0 1362.05 998.17"
    {...props}
  >
    <style>
      {
        ".st0{fill:#fff}.st2{fill:#f6e8cd}.st3{fill:#faf0dc}.st4{fill:#f6d0bb}.st5{fill:#f6dacc}.st6{fill:#ebe9e4}.st7{fill:#f2f0eb}.st8{fill:#b6e4b8}.st11{fill:#ce815a}.st12{fill:#f9b993}.st13{fill:#a2bd5e}.st22{fill:#ccc8b0}.st25{fill:#f7f5f0}.st26{fill:#bac6e6}.st27{fill:#b1c1e6}.st28{fill:#f6be9d}.st29{fill:#cad2e6}.st30{fill:#91a6db}.st31{fill:#f0c2a8}.st32{fill:#c4744a}.st33{fill:#e0ad90}.st35{fill:#d3e0ad}.st36{fill:#b8cf8f}.st37{fill:#f6d9ca}.st39{fill:#eb996a}.st40{fill:#f0ac84}.st44{fill:#f9ad7f}.st48{fill:#ffce70}.st50{fill:#ffc861}.st52{fill:#d9916d}.st53{fill:#ba6438}"
      }
    </style>
    <path d="M0 0h1361.12v997.64H0z" className="st0" />
    <path
      id="XMLID_00000174567635578523672350000007667524625562774685_"
      d="M498.96 0c-30.27 10.6-233.9 85.69-308.64 296.44-53.8 151.71-39.16 370.09 72.92 434.75 132.54 76.47 300.31-120.41 464.91-31.06 82.08 44.56 70.58 109.91 151.77 174.53 77.28 61.51 215.24 103.79 481.21 28.51V74.94a578.732 578.732 0 0 0-92.65-22.86c-34.8-5.58-202.74-32.52-364.11 51.75-108.62 56.72-200.79 153.36-264.17 148.58-37.2-2.8-59.84-22.3-59.84-22.3s-19.27-15.57-27.3-42.36C528.15 104.57 639.34 11.24 653.04 0H498.96z"
      style={{
        fill: "#fefbf2",
      }}
    />
    <path
      d="M335.59 125.46h-35.94c-43.43 43.12-83.86 99.18-109.33 170.98-36.23 102.18-41.42 234.59-6.7 329.92h151.97v-500.9z"
      className="st2"
    />
    <path
      d="M335.59 127.55v-2.09h-35.94c-43.43 43.12-83.86 99.18-109.33 170.98-3.16 8.9-6.07 18.05-8.75 27.37l154.02-196.26z"
      className="st3"
    />
    <path
      d="M263.28 151.94h11.67v34.76h-11.67zM297.92 151.94h11.67v34.76h-11.67zM228.27 208.65h11.67v34.76h-11.67zM263.28 208.65h11.67v34.76h-11.67zM297.92 208.65h11.67v34.76h-11.67zM193.63 265.37h11.67v34.76h-11.67zM228.27 265.37h11.67v34.76h-11.67zM263.28 265.37h11.67v34.76h-11.67zM297.92 265.37h11.67v34.76h-11.67zM193.63 322.08h11.67v34.76h-11.67zM228.27 322.08h11.67v34.76h-11.67zM263.28 322.08h11.67v34.76h-11.67zM297.92 322.08h11.67v34.76h-11.67zM157.72 378.79h11.67v34.76h-11.67zM193.63 378.79h11.67v34.76h-11.67zM228.27 378.79h11.67v34.76h-11.67zM263.28 378.79h11.67v34.76h-11.67zM297.92 378.79h11.67v34.76h-11.67zM157.72 435.51h11.67v34.76h-11.67zM193.63 435.51h11.67v34.76h-11.67zM228.27 435.51h11.67v34.76h-11.67zM263.28 435.51h11.67v34.76h-11.67zM297.92 435.51h11.67v34.76h-11.67zM157.72 492.22h11.67v34.76h-11.67zM193.63 492.22h11.67v34.76h-11.67zM228.27 492.22h11.67v34.76h-11.67zM263.28 492.22h11.67v34.76h-11.67zM297.92 492.22h11.67v34.76h-11.67zM157.72 548.93h11.67v34.76h-11.67zM193.63 548.93h11.67v34.76h-11.67zM228.27 548.93h11.67v34.76h-11.67zM263.28 548.93h11.67v34.76h-11.67zM297.92 548.93h11.67v34.76h-11.67z"
      className="st0"
    />
    <path
      id="XMLID_00000148622544113661843050000002127101365451141822_"
      d="M1042.07 399.01h200.97v500.9h-200.97z"
      className="st2"
    />
    <path d="M1195.46 399.01h-153.39v210.71z" className="st3" />
    <path
      d="M1065.18 425.5h11.67v34.76h-11.67zM1101.09 425.5h11.67v34.76h-11.67zM1135.73 425.5h11.67v34.76h-11.67zM1170.74 425.5h11.67v34.76h-11.67zM1205.38 425.5h11.67v34.76h-11.67zM1065.18 482.21h11.67v34.76h-11.67zM1101.09 482.21h11.67v34.76h-11.67zM1135.73 482.21h11.67v34.76h-11.67zM1170.74 482.21h11.67v34.76h-11.67zM1205.38 482.21h11.67v34.76h-11.67zM1065.18 538.93h11.67v34.76h-11.67zM1101.09 538.93h11.67v34.76h-11.67zM1135.73 538.93h11.67v34.76h-11.67zM1170.74 538.93h11.67v34.76h-11.67zM1205.38 538.93h11.67v34.76h-11.67zM1065.18 595.64h11.67v34.76h-11.67zM1101.09 595.64h11.67v34.76h-11.67zM1135.73 595.64h11.67v34.76h-11.67zM1170.74 595.64h11.67v34.76h-11.67zM1205.38 595.64h11.67v34.76h-11.67zM1065.18 652.35h11.67v34.76h-11.67zM1101.09 652.35h11.67v34.76h-11.67zM1135.73 652.35h11.67v34.76h-11.67zM1170.74 652.35h11.67v34.76h-11.67zM1205.38 652.35h11.67v34.76h-11.67zM1065.18 709.06h11.67v34.76h-11.67zM1101.09 709.06h11.67v34.76h-11.67zM1135.73 709.06h11.67v34.76h-11.67zM1170.74 709.06h11.67v34.76h-11.67zM1205.38 709.06h11.67v34.76h-11.67zM1065.18 765.78h11.67v34.76h-11.67zM1101.09 765.78h11.67v34.76h-11.67zM1135.73 765.78h11.67v34.76h-11.67zM1170.74 765.78h11.67v34.76h-11.67zM1205.38 765.78h11.67v34.76h-11.67zM1065.18 822.49h11.67v34.76h-11.67zM1101.09 822.49h11.67v34.76h-11.67zM1135.73 822.49h11.67v34.76h-11.67zM1170.74 822.49h11.67v34.76h-11.67zM1205.38 822.49h11.67v34.76h-11.67z"
      className="st0"
    />
    <path d="M1361.12 317.46H1179.8v500.9h181.32v-500.9z" className="st4" />
    <path d="M1354.26 317.46H1179.8V621.4z" className="st5" />
    <path
      d="M1202.91 343.95h11.67v34.76h-11.67zM1238.82 343.95h11.67v34.76h-11.67zM1273.45 343.95h11.67v34.76h-11.67zM1308.47 343.95h11.67v34.76h-11.67zM1343.11 343.95h11.67v34.76h-11.67zM1202.91 400.66h11.67v34.76h-11.67zM1238.82 400.66h11.67v34.76h-11.67zM1273.45 400.66h11.67v34.76h-11.67zM1308.47 400.66h11.67v34.76h-11.67zM1343.11 400.66h11.67v34.76h-11.67zM1202.91 457.37h11.67v34.76h-11.67zM1238.82 457.37h11.67v34.76h-11.67zM1273.45 457.37h11.67v34.76h-11.67zM1308.47 457.37h11.67v34.76h-11.67zM1343.11 457.37h11.67v34.76h-11.67zM1202.91 514.09h11.67v34.76h-11.67zM1238.82 514.09h11.67v34.76h-11.67zM1273.45 514.09h11.67v34.76h-11.67zM1308.47 514.09h11.67v34.76h-11.67zM1343.11 514.09h11.67v34.76h-11.67zM1202.91 570.8h11.67v34.76h-11.67zM1238.82 570.8h11.67v34.76h-11.67zM1273.45 570.8h11.67v34.76h-11.67zM1308.47 570.8h11.67v34.76h-11.67zM1343.11 570.8h11.67v34.76h-11.67zM1202.91 627.51h11.67v34.76h-11.67zM1238.82 627.51h11.67v34.76h-11.67zM1273.45 627.51h11.67v34.76h-11.67zM1308.47 627.51h11.67v34.76h-11.67zM1343.11 627.51h11.67v34.76h-11.67zM1202.91 684.23h11.67v34.76h-11.67zM1238.82 684.23h11.67v34.76h-11.67zM1273.45 684.23h11.67v34.76h-11.67zM1308.47 684.23h11.67v34.76h-11.67zM1343.11 684.23h11.67v34.76h-11.67zM1202.91 740.94h11.67v34.76h-11.67zM1238.82 740.94h11.67v34.76h-11.67zM1273.45 740.94h11.67v34.76h-11.67zM1308.47 740.94h11.67v34.76h-11.67zM1343.11 740.94h11.67v34.76h-11.67z"
      className="st0"
    />
    <path
      id="XMLID_00000119818103987671713550000005887015004866226822_"
      d="M270.46 221.05h222.46v542.33H270.46z"
      className="st6"
    />
    <path d="M478.57 221.05H270.46v271.56z" className="st7" />
    <path
      d="M302.48 249.73h16.17v37.64h-16.17zM352.25 249.73h16.17v37.64h-16.17zM400.25 249.73h16.17v37.64h-16.17zM448.77 249.73h16.17v37.64h-16.17zM302.48 311.13h16.17v37.64h-16.17zM352.25 311.13h16.17v37.64h-16.17zM400.25 311.13h16.17v37.64h-16.17zM448.77 311.13h16.17v37.64h-16.17zM302.48 372.54h16.17v37.64h-16.17zM352.25 372.54h16.17v37.64h-16.17zM400.25 372.54h16.17v37.64h-16.17zM448.77 372.54h16.17v37.64h-16.17zM302.48 433.94h16.17v37.64h-16.17zM352.25 433.94h16.17v37.64h-16.17zM400.25 433.94h16.17v37.64h-16.17zM448.77 433.94h16.17v37.64h-16.17zM302.48 495.35h16.17v37.64h-16.17zM352.25 495.35h16.17v37.64h-16.17zM400.25 495.35h16.17v37.64h-16.17zM448.77 495.35h16.17v37.64h-16.17zM302.48 556.75h16.17v37.64h-16.17zM352.25 556.75h16.17v37.64h-16.17zM400.25 556.75h16.17v37.64h-16.17zM448.77 556.75h16.17v37.64h-16.17zM302.48 618.15h16.17v37.64h-16.17zM352.25 618.15h16.17v37.64h-16.17zM400.25 618.15h16.17v37.64h-16.17zM448.77 618.15h16.17v37.64h-16.17zM302.48 679.56h16.17v37.64h-16.17zM352.25 679.56h16.17v37.64h-16.17zM400.25 679.56h16.17v37.64h-16.17zM448.77 679.56h16.17v37.64h-16.17z"
      className="st0"
    />
    <path
      id="XMLID_00000102517232058773308500000002865890817253633949_"
      d="M935.97 491.78c-5.85 3.88-16.76 9.78-30.73 9.63-26.49-.28-55.9-22.21-56.41-50.9-.44-24.92 21.18-41.17 23.85-43.11-2.4-2.51-16.34-17.51-14.68-39.44 1.4-18.39 13.35-35.99 31.18-42.65 12.03-4.49 22.96-2.59 27.97-1.38-4.62-28.54 5.13-55.88 25.68-69.71 13.96-9.4 42.16-18.78 58.7-2.75 10.54 10.21 13.28 27.77 7.8 44.94 22.93-14.63 51.56-9.99 65.58 7.8 11.6 14.71 11.29 35.97 2.75 51.36-7.69 13.86-20.26 19.71-24.76 21.55 21.54 27.61 22.28 63.06 4.59 84.38-14.93 17.98-37.95 19.87-43.57 20.18-.76 17.25-10.64 32.24-24.76 37.6-19.07 7.25-44.07-3.77-53.19-27.5z"
      className="st8"
    />
    <linearGradient
      id="SVGID_1_"
      x1={848.826}
      x2={1010.607}
      y1={352.11}
      y2={352.11}
      gradientUnits="userSpaceOnUse"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#b6e4bd",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#f1f29d",
        }}
      />
    </linearGradient>
    <path
      d="M1001.55 251.47c-16.54-16.03-44.74-6.65-58.7 2.75-20.55 13.83-30.3 41.17-25.68 69.71-5.01-1.22-15.95-3.11-27.97 1.38-17.84 6.66-29.79 24.26-31.18 42.65-1.67 21.93 12.27 36.93 14.68 39.44-2.67 1.94-24.28 18.19-23.85 43.11.07 3.76.64 7.41 1.63 10.91.96-4.59 2.41-9.01 4.36-13.18 9.89-21.1 30.4-31.21 40.98-35.39-17.9-17.63-20.38-43.59-8.38-59.8 12.02-16.23 33.7-16.05 36.08-16 3.12-42.74 31.67-73.32 61.57-75.01 9.67-.55 18.36 2 25.54 5.47-1.68-6.24-4.69-11.78-9.08-16.04z"
      style={{
        fill: "url(#SVGID_1_)",
      }}
    />
    <linearGradient
      id="SVGID_00000018941266757898670080000002741376849454242995_"
      x1={1012.415}
      x2={1055.639}
      y1={489.403}
      y2={408.731}
      gradientUnits="userSpaceOnUse"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#b5cc7a",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#b6e4bd",
        }}
      />
    </linearGradient>
    <path
      d="M1057.5 461.51c13.72-16.53 16.35-41.56 6.8-64.84-1.36 9.08-4.84 20.52-13.19 31.39-18.8 24.48-47.18 26.39-51.36 26.6 2.3 14.14.29 25.06-1.83 32.1-4.47 14.81-13.58 26.09-23.99 34.61 5.23.41 10.43-.25 15.25-2.08 14.13-5.36 24.01-20.36 24.76-37.6 5.61-.31 28.63-2.19 43.56-20.18z"
      style={{
        fill: "url(#SVGID_00000018941266757898670080000002741376849454242995_)",
      }}
    />
    <path
      id="XMLID_00000053525300354275395740000005649683489502852481_"
      d="M915.79 681.18c17.25-39.94 21.81-73.95 22.93-96.31 2.14-42.68-6.46-79.02-14.68-113.73a600.038 600.038 0 0 0-11.92-43.11c3.45 5.89 8.47 15.01 13.62 26.69 5.79 13.15 7.02 18.85 9.22 18.78 5.12-.16 6.53-31.23 11.56-60.6 3.59-20.97 10.66-50.63 26.14-85.76a463.351 463.351 0 0 0-16.51 66.04c-10.8 62.39-7.85 121.66-3.67 121.99 1.44.11 2.25-6.84 8.25-26.6 5.04-16.6 10.06-30.09 13.76-39.44-9.39 35.08-12.1 63.74-12.84 83.46-.75 20.01.53 31.03.92 64.2.35 30.21-.31 54.75-.92 70.62-15.29 4.6-30.58 9.18-45.86 13.77z"
      className="st11"
    />
    <path
      d="M944.69 492.93c1.39 5.2 3.25 10.29 5.05 15.21.28.76.55 1.51.82 2.26-.25-1.35-.49-2.98-.7-4.85-1.52-4.2-3.03-8.5-4.2-12.87-3.24-12.13-1.69-25.97 1.39-53.55 4.06-36.33 13.11-73.35 21.91-103.28-1.31 3.19-2.57 6.33-3.75 9.41-7.88 28.08-15.52 61.19-19.16 93.76-3.1 27.7-4.65 41.6-1.36 53.91zM950.18 555.5c-.24 2.51.7 5.84 2.97 7.34 2.19 1.45 4.77.76 7.26.1.46-.12.91-.24 1.35-.34l-.03-1.02c-.53.12-1.05.26-1.58.4-2.39.64-4.64 1.23-6.45.04-1.92-1.27-2.74-4.25-2.53-6.41.36-3.76 4.34-8 10.24-10.99v-1.12c-5.02 2.47-10.75 6.96-11.23 12zM961.92 659.81c-7.66 4.75-12.8 6.23-15.31 4.34-3.09-2.32-1.83-9.48-.61-16.4 1.27-7.2 2.47-14-1.03-16.05-2.95-1.73-7.4 1.03-11.71 3.69-.79.49-1.58.98-2.36 1.43-.11.45-.23.9-.34 1.35 1.04-.58 2.13-1.25 3.23-1.93 3.87-2.39 8.26-5.1 10.68-3.67 2.89 1.7 1.7 8.47.55 15.01-1.27 7.22-2.59 14.69 1 17.37.87.65 1.97.98 3.3.98 2.99 0 7.16-1.63 12.57-4.92 0-.39.01-.8.03-1.2zM932.78 510.69c.32 1.7.64 3.4.94 5.12.01-.82.03-1.65.04-2.52.38-24.24-4.59-48.62-14.79-72.76-2.6-5.06-4.96-9.26-6.85-12.49.01.04.03.08.04.12 13.68 27.3 20.61 54.99 20.62 82.53z"
      className="st12"
    />
    <path
      d="M953.26 645.45c2.65.95 6.01-.9 9.13-3.62.01-.45.02-.92.02-1.37-3.62 3.32-6.76 4.78-8.82 4.04-2.14-.77-2.02-4.64-1.78-12.37.21-6.89.53-17.3-.64-31.69l-.09-1.11c-.47-5.66-.88-10.54 1.7-12.19 2.29-1.47 6.15.1 9.68 2.23-.01-.4-.01-.81-.02-1.2-3.76-2.19-7.64-3.51-10.2-1.88-3.08 1.97-2.65 7.14-2.15 13.12l.09 1.1c1.17 14.33.85 24.71.63 31.58-.26 8.48-.38 12.34 2.45 13.36z"
      className="st12"
    />
    <path
      d="M956.86 600.51c-3.06 1.61-2.47 7.44-1.18 20.21.71 6.98 1.49 8.58 2.98 9.54 1.16.75 2.51.88 3.9.67 0-.34.01-.67.01-1.01-1.24.21-2.41.12-3.37-.5-1.18-.76-1.87-2.27-2.53-8.8-1.17-11.56-1.81-17.93.65-19.23 1.27-.67 3.19-.08 5.29 1.16 0-.38-.01-.77-.01-1.16-2.09-1.14-4.17-1.71-5.74-.88zM949.96 572.95c3.61 2.13 7.97 2.57 12.16 1.5l-.03-1.03c-4.01 1.08-8.18.69-11.62-1.34-7.51-4.43-7.77-15-5.56-21.1 1.21-3.33 2.97-4.78 4.83-6.31 1.38-1.13 2.8-2.31 4.1-4.27 4.58-6.92 2.61-15.94-.71-25.73-.22.35-.43.51-.65.49a.62.62 0 0 1-.27-.11c3.33 9.75 5.16 18.22.8 24.8-1.21 1.84-2.58 2.96-3.9 4.05-1.89 1.55-3.84 3.16-5.14 6.74-2.17 5.98-2.46 17.32 5.99 22.31z"
      className="st12"
    />
    <path
      d="M946.98 536.4c4.43-7.34.69-16.56-3.65-27.23-1.7-4.18-3.46-8.51-4.92-13.13-2.29-7.22-3.68-14.83-4.21-22.78-.36-.24-.71-.67-1.08-1.29.46 8.52 1.9 16.67 4.34 24.38 1.47 4.66 3.24 9 4.95 13.21 4.24 10.42 7.9 19.42 3.73 26.33-1.74 2.87-4.62 5.08-8.59 6.62.04.34.07.69.1 1.03 4.3-1.63 7.43-4.01 9.33-7.14zM943.33 609.89c5.91-11.81 3.31-23.46 1.42-31.98-1.25-5.61-3.24-11.09-5.88-16.32.03.81.05 1.63.07 2.44 2.12 4.55 3.75 9.27 4.83 14.09 1.97 8.83 4.42 19.82-1.33 31.31-2.12 4.22-4.89 7.59-7.8 10.27-.1.53-.2 1.07-.3 1.61 3.33-2.87 6.57-6.61 8.99-11.42z"
      className="st12"
    />
    <path
      d="M329.96 601.18c-10.28-4.96-20.64-3.24-25.45-2.06 8.5-9.61 7.65-24.08-1.06-32.06-7.75-7.1-20.17-7.67-29.18-.85 1.74-5.17-.26-10.48-3.89-12.46-4.55-2.48-11.96.1-14.29 6.96-3.1-18.02-18.68-31.11-36.18-31.07-18.86.04-35.22 15.34-36.46 35.08-5.11-3.18-10.99-4.46-16.71-3.93 11.42 68.37 38.04 127.65 82.09 160.84a35.96 35.96 0 0 0 1.75-8.23c6.42 2.71 27.74 10.76 51.6 2.54 26.63-9.18 45.76-34.97 49.11-65.91 3.23-21.64-5.78-41.35-21.33-48.85z"
      className="st13"
    />
    <linearGradient
      id="SVGID_00000103237155944517586420000017937162223244264119_"
      x1={1049.336}
      x2={1056.967}
      y1={747.558}
      y2={686.157}
      gradientUnits="userSpaceOnUse"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#cfe4ac",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#d8e4c2",
        }}
      />
    </linearGradient>
    <path
      d="M1187.77 637.72c-176.89 6.13-332.95 30.64-463.41 60.41 1.26.65 2.53 1.32 3.79 2 82.08 44.56 70.58 109.91 151.77 174.53 77.28 61.51 215.24 103.79 481.21 28.51V637.82a2458.72 2458.72 0 0 0-173.36-.1z"
      style={{
        fill: "url(#SVGID_00000103237155944517586420000017937162223244264119_)",
      }}
    />
    <linearGradient
      id="SVGID_00000163063880779949064300000011981108212636167325_"
      x1={385.555}
      x2={388.539}
      y1={681.657}
      y2={657.647}
      gradientUnits="userSpaceOnUse"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#cfe4ac",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#d8e4c2",
        }}
      />
    </linearGradient>
    <path
      d="M176.36 603.91c16.09 56.45 44.52 102.85 86.87 127.28 99.45 57.38 218.73-39.14 341.63-53.17-91.44-28.99-200.62-54.65-325.12-67.12-35.77-3.58-70.27-5.82-103.38-6.99z"
      style={{
        fill: "url(#SVGID_00000163063880779949064300000011981108212636167325_)",
      }}
    />
    <path
      id="XMLID_00000062151258760593804510000000307875419572535945_"
      d="M1167.74 552.76c-24.78 3.9-43.65-13.31-43.56-28.76.06-11.37 10.39-23.21 25.38-27.91-7.09.41-12.37-5.51-11.8-11.04.49-4.79 5.36-9.09 11.37-8.84-22.86-22.88-22.7-53.44-8.88-65.98 12.45-11.3 33.54-5.74 35.95-5.08-1.83-21.85 11.99-39.75 27.07-42.29 17.79-3 40.52 14.8 40.18 41.87 2.33-6.85 9.74-9.43 14.29-6.96 3.64 1.98 5.63 7.28 3.89 12.46 10.68-6.94 23.45-5.61 29.18.85 7.05 7.93 5.15 25.44-9.3 34.26 27.17 5.86 41.32 30.42 35.95 48.22-3.64 12.07-16.28 21.13-31.3 23.69 11.66 13.37 12.15 31.96 3.38 42.72-10.9 13.38-36.24 14.78-51.6-2.54-1.73 18.82-17.82 33.02-35.95 32.57-19.16-.5-35.03-17.23-34.25-37.24z"
      style={{
        fill: "#bbdfab",
      }}
    />
    <linearGradient
      id="SVGID_00000111871817882468937550000007448346059690134698_"
      x1={1187.071}
      x2={1152.203}
      y1={461.769}
      y2={434.983}
      gradientUnits="userSpaceOnUse"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#bbdfab",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#f2e794",
        }}
      />
    </linearGradient>
    <path
      d="M1203.27 362.86c-15.08 2.55-28.9 20.44-27.07 42.29-2.41-.67-23.5-6.22-35.95 5.08-13.81 12.54-13.98 43.1 8.88 65.98-6.02-.25-10.88 4.06-11.37 8.84-.57 5.53 4.71 11.45 11.8 11.04-14.98 4.7-25.31 16.55-25.38 27.91-.05 9.75 7.44 20.19 19.2 25.59-2.4-4.39-4.4-9.87-4.82-16.34-.99-15.2 7.08-30.01 20.78-38.55-5.24-.21-8.76-5-7.95-9.17.77-3.97 5.39-7.02 10.18-5.73-22.42-18.75-23.97-44.89-14.17-54.31 7.56-7.27 23.95-6.54 39 1.64-9.28-22.06 1.32-46.31 20.18-53.66 7.56-2.94 16.05-3.01 24.04-.51-7.93-7.75-18.28-11.63-27.35-10.1z"
      style={{
        fill: "url(#SVGID_00000111871817882468937550000007448346059690134698_)",
      }}
    />
    <linearGradient
      id="SVGID_00000039098696083657995890000009044751258574090385_"
      x1={1249.944}
      x2={1318.591}
      y1={510.536}
      y2={510.536}
      gradientUnits="userSpaceOnUse"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#9fc767",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#bbdfab",
        }}
      />
    </linearGradient>
    <path
      d="M1317.47 493.55c4.3-14.25-3.92-32.81-21.3-42.67 3.29 7.02 8.6 18.64 5.34 30.68-3.71 13.67-16.88 22.03-27.21 26.9 9.18 10.44 11.34 25.37 5.5 37.91-5.46 11.74-17.03 19.42-29.85 20.14 14.35 6.91 31.23 3.71 39.61-6.56 8.76-10.75 8.27-29.35-3.38-42.72 15-2.55 27.65-11.61 31.29-23.68z"
      style={{
        fill: "url(#SVGID_00000039098696083657995890000009044751258574090385_)",
      }}
    />
    <path
      id="XMLID_00000169536376887342347950000009170090938329103801_"
      d="M1241.69 702.87c-.36-33.12-.04-61.93.49-85.4 1.43-63.46 4.1-75.84 5.5-81.63 1.1-4.56 3.59-14.1 4.65-27.49 1.3-16.31-.19-29.87-1.69-38.91-.54 18.68-2.71 33.61-4.65 43.99-2.84 15.14-4.36 16.06-5.08 16.07-2.16.04-4.62-8.06-8.88-37.64-2.34-16.24-5.31-39.6-7.61-68.52-1.72 15.39-3.09 38.74.42 66.83 3.69 29.48 11.62 54.99 8.04 56.25-2.05.72-6.69-6.96-11.42-14.8-5-8.29-8.17-15.75-10.15-21.15 1.27 9.01 3.74 22.73 8.88 38.91 4.47 14.07 6.7 16.57 9.3 24.53 6.56 20.08 2.19 34.26-9.73 98.12-2.66 14.28-4.68 25.54-5.63 30.89 9.19-.01 18.38-.03 27.56-.05z"
      className="st11"
    />
    <path
      d="M1237.34 659.61c-.26-3.34 1.28-5.31 3.06-7.59.41-.52.84-1.08 1.26-1.66.01-.61.01-1.21.02-1.81-.65 1.04-1.36 1.96-2.07 2.86-1.83 2.34-3.55 4.55-3.27 8.28.3 3.82 2.59 7.61 5.2 10.16v-1.43c-2.16-2.35-3.95-5.57-4.2-8.81zM1243.38 580.21c.1-2.13.19-4.15.29-6.07-1.82-16.34-1.98-27.36 1.91-41.85 1.05-3.93 1.91-6.27 2.68-8.34.99-2.69 1.84-5.01 2.72-9.77 2.12-11.49 1.87-23.41-.74-35.62-.08 1.33-.16 2.64-.26 3.93 1.88 10.78 1.9 21.32.02 31.51-.86 4.67-1.66 6.85-2.68 9.6-.77 2.09-1.64 4.46-2.7 8.43-4.39 16.36-3.68 28.28-1.24 48.18zM1222.92 467.21c1.1 6.58 2.49 13.62 4.26 21.04.47 1.98 1 4.09 1.55 6.32 2.92 11.76 6.57 26.4 8.99 46.82 1.53 12.94 1.95 22.61-.45 33.77-1.5 6.96-3.29 10.8-4.85 13.73.04.64.07 1.29.09 1.94.04-.08.08-.15.12-.23 1.72-3.17 3.86-7.11 5.61-15.23 2.43-11.29 2.01-21.06.46-34.1-2.43-20.48-6.08-35.16-9.01-46.95-.55-2.23-1.08-4.32-1.55-6.31a312.37 312.37 0 0 1-5.47-28.92c.06 2.64.13 5.35.25 8.12zM1241.57 687.14c-2.44-3.5-5.25-7-8.5-10.41-3.78-3.97-8-7.37-12.26-10.29-.07.35-.13.71-.2 1.07 4.08 2.82 8.12 6.11 11.74 9.91 3.58 3.76 6.63 7.64 9.23 11.49 0-.59-.01-1.18-.01-1.77zM1230.97 610.87c-.1.7-.21 1.42-.32 2.15 1.77 4.68 4.4 8.75 6.84 12.54 1.65 2.55 3.21 4.97 4.39 7.37l.03-2.09c-1.06-1.9-2.29-3.83-3.59-5.83-2.71-4.2-5.65-8.77-7.35-14.14zM1230.92 629.78c-.44-3.62-1.01-6.28-1.64-8.48-.14.78-.28 1.58-.42 2.39.4 1.73.76 3.74 1.06 6.21 1.18 9.64 2.8 22.85-3.49 27.98-1.25 1.02-2.74 1.65-4.39 2.01-.07.35-.13.7-.2 1.06 1.96-.36 3.74-1.08 5.22-2.29 6.73-5.48 5.07-19.01 3.86-28.88z"
      className="st12"
    />
    <path
      d="M1220.03 548.88c.05.17.1.33.16.5.15.46.29.91.44 1.35 4.74 4.22 8.97 8.59 10.22 16.36.61 3.75.28 6.85-.48 9.7.17.62.34 1.24.49 1.86 1.12-3.4 1.72-7.06.97-11.72-1.42-8.8-6.43-13.35-11.8-18.05z"
      className="st12"
    />
    <path
      id="XMLID_00000122700403193836419130000018010265994648012215_"
      d="M1074.96 714.22c9.02-20.43 30.34-30.39 47.78-24.97 18.91 5.88 24.57 27.16 24.87 28.37.39-7.09 6.87-11.67 12.3-10.48 4.7 1.03 8.43 6.35 7.5 12.3 25.32-20.13 55.66-16.51 66.56-1.37 9.82 13.65 1.92 33.97.98 36.29 21.92.65 38.14 16.4 38.97 31.67.98 18.01-19.28 38.59-46.14 35.19 6.55 3.09 8.27 10.75 5.3 14.99-2.38 3.39-7.87 4.77-12.82 2.46 5.69 11.4 2.92 23.93-4.14 28.9-8.67 6.11-25.86 2.25-32.99-13.12-8.89 26.34-34.89 37.62-51.97 30.27-11.58-4.98-19.16-18.57-20-33.77-14.6 10.07-33.13 8.46-42.83-1.46-12.06-12.34-10.59-37.68 8.35-50.98-30.13-.3-50.83-26.44-47.13-49.45 3.01-18.62 21.94-34.67 45.41-34.84z"
      style={{
        fill: "#b5cc7a",
      }}
    />
    <path
      d="M1236.06 751.26c.24-.72.49-1.55.74-2.46-4.52-13.13-9.04-26.25-13.56-39.38-14.3-6.86-36.53-5.33-55.85 10.02.93-5.95-2.8-11.27-7.5-12.3-5.43-1.19-11.91 3.39-12.3 10.48-.3-1.21-5.96-22.49-24.87-28.37-17.44-5.42-38.75 4.54-47.78 24.97-23.46.16-42.4 16.22-45.4 34.85-1.56 9.69 1.22 19.93 7.2 28.56 3.26 1.79 6.51 3.58 9.77 5.37-6.6-16.3-2.28-34.02 9.63-43.11 11.54-8.8 25.61-6.01 27.97-5.5.47-1.9 4.32-16.55 17.43-22.01 13.61-5.67 31.77.82 41.27 16.97.22-.24 4.75-4.98 11.01-3.67 4.75.99 8.45 5.03 9.17 10.09 6.26-5.3 12.19-7.62 16.51-8.71 18.69-4.73 40.27 5.02 56.56 24.2z"
      style={{
        fill: "#bfd680",
      }}
    />
    <path
      d="M1361.12 662.64c-14.2-11-40.93-8.33-57.4 12.7-1.62-16.86-16.55-26.21-26.76-23.32-8.31 2.35-15.08 13.25-13.5 25.89-3.9-3.82-9.54-4.34-12.91-1.93-4.21 3.01-5.13 10.81.02 15.9-12.02-6.96-27.21-5.52-37.67 3.55-10.57 9.16-14.1 24.19-8.71 37.15-24.21-3.33-46.29 8.15-54.11 26.6-6.64 15.64-.74 30.91.92 34.85 40.91 4.64 81.82 9.28 122.73 13.91-2.85 5.31-1.1 11.56 2.99 14.09 4.73 2.93 12.36.76 15.08-5.8-2.31 15.54 3.84 30 14.04 35.02 13.87 6.82 37.58-2.65 44.89-26.64 3.35 1.79 6.85 2.99 10.4 3.64-.01-55.21-.01-110.41-.01-165.61z"
      className="st13"
    />
    <path
      d="M1361.12 662.64c-14.2-11-40.93-8.33-57.4 12.7-1.62-16.86-16.55-26.21-26.76-23.32-8.31 2.35-15.08 13.25-13.5 25.89-3.9-3.82-9.54-4.34-12.91-1.93-4.21 3.01-5.13 10.81.02 15.9-12.02-6.96-27.21-5.52-37.67 3.55-10.57 9.16-14.1 24.19-8.71 37.15-24.21-3.33-46.29 8.15-54.11 26.6-6.64 15.64-.74 30.91.92 34.85l14.29 1.62c.58-8.03 3.02-15.84 7.36-22.72 10.07-15.96 29.12-24.91 49.07-23.39-7.14-13.83-3.72-29.98 6.88-38.06 11.89-9.06 27.04-3.57 27.97-3.21-1.36-7.09 2.57-13.38 7.8-14.68 5.2-1.29 9.72 2.86 10.09 3.21.36-1.21 3.16-10.09 10.68-12.57 8.44-2.78 20.24 3.38 24.64 16.24 2.92-3 14.01-13.66 29.35-13.3 7.76.18 15.4 3.15 22.01 8.3-.02-10.95-.02-21.89-.02-32.83z"
      style={{
        fill: "#adc76d",
      }}
    />
    <path
      d="M1361.12 903.17V763.29H794.33c21.84 34.38 37.37 73.01 85.58 111.38 77.29 61.49 215.24 103.77 481.21 28.5z"
      className="st22"
    />
    <path
      d="M1361.12 903.17V785.03H807.37c16.59 28.54 34.27 59.16 72.55 89.63 77.28 61.5 215.23 103.78 481.2 28.51z"
      style={{
        fill: "#dddcd0",
      }}
    />
    <path
      d="M552.4 185.3c-11.25-42.1 11.6-86.55 38.52-121.48-10-6.16-21.77-9.72-34.38-9.72-36.27 0-65.67 29.4-65.67 65.67-.01 34.88 27.19 63.4 61.53 65.53z"
      style={{
        fill: "#ffe242",
      }}
    />
    <path
      d="M533.17 339.99c53.12-.01 106.25-.03 159.37-.04-2.14-2.58-10.28-11.73-24.32-14.17-11.6-2.01-20.71 1.68-24.06 3.24-1.37-2.58-8.6-15.64-23.4-19.3-15.91-3.94-34.06 4.59-43.57 21.55-2.76-2.08-8.01-5.35-15.13-5.96-16.96-1.44-28.14 13.63-28.89 14.68zM407.56 128.41h122.9c-4.99-16.12-18.8-27.96-35.31-30.27-17.39-2.43-34.71 6.12-43.57 21.55-2.76-2.08-8.01-5.35-15.13-5.96-16.96-1.45-28.13 13.62-28.89 14.68zM1207.03 200.78h-122.9c4.99-16.12 18.8-27.96 35.31-30.27 17.39-2.43 34.71 6.12 43.57 21.55 2.76-2.08 8.01-5.35 15.13-5.96 16.96-1.44 28.13 13.63 28.89 14.68z"
      className="st0"
    />
    <path d="M372.12 832.81v28.49h54.15v-26.66z" className="st4" />
    <path
      d="m364.97 886.08 96.54-.05-31.06-18.83-.01-14.02-65.49.04z"
      className="st8"
    />
    <path
      d="M417.47 868.2c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM417.47 865.26c-.55 0-1-.45-1-1s.45-1 1-1l12.98-.01c.55 0 1 .45 1 1s-.45 1-1 1l-12.98.01zM417.47 862.31c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM417.47 859.36c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98z"
      className="st0"
    />
    <path d="m461.51 886.03-11.28-6.84-85.27.04.01 6.85z" className="st25" />
    <path
      id="XMLID_00000060032201269555078360000013644693289006323588_"
      d="M350.88 844.28h94.91c2.76-48.67 9.52-97.33 12.28-146a41.085 41.085 0 0 0-14.56-33.76c-35.39-29.79-90.77-59.58-126.16-89.38-23.39 17.18-46.79 34.35-70.18 51.53 39.1 29.51 78.2 59.02 117.29 88.53-4.52 43.03-9.05 86.06-13.58 129.08z"
      className="st26"
    />
    <path
      d="m364.47 715.2-7.86 74.66c30.85-39.84 61.71-79.67 92.56-119.51-1.68-2.1-3.56-4.07-5.66-5.83-16.06-13.52-36.24-27.04-56.79-40.56-7.42 30.42-14.83 60.83-22.25 91.24z"
      className="st27"
    />
    <path
      d="M437.58 898.02h-42.33c-3.3 0-5.97-2.67-5.97-5.97s2.67-5.97 5.97-5.97h42.33c3.3 0 5.97 2.67 5.97 5.97s-2.68 5.97-5.97 5.97z"
      className="st22"
    />
    <path
      d="M897.33 948.76c-51.25 0-92.94-41.69-92.94-92.94s41.69-92.94 92.94-92.94 92.94 41.69 92.94 92.94-41.69 92.94-92.94 92.94zm0-155.88c-34.71 0-62.94 28.23-62.94 62.94s28.24 62.94 62.94 62.94 62.94-28.24 62.94-62.94-28.23-62.94-62.94-62.94z"
      className="st6"
    />
    <path
      d="M539.94 864.16c-19-47.49-39.99-95.99-58.99-143.48 8.8-4.16 87.23-39.56 167.85 2.75 91.02 47.77 99.38 149.76 99.75 155.47l-185.36 1c-10.24 0-19.45-6.24-23.25-15.74z"
      className="st5"
    />
    <path
      d="M1052.39 874.64c.37-14.32 1.29-112.75-76.41-180.41-67.65-58.91-83.83-70.97-170.54-30.43-18.59-47.96-37.18-95.93-55.77-143.89-1.93-4.98-7.4-7.59-12.48-5.97l-.19.06c-5.49 1.75-8.41 7.73-6.41 13.14 31.23 84.13 62.47 168.26 93.7 252.39 11.94 35.7-60.14 71.4-48.2 107.09l276.3-11.98"
      className="st28"
    />
    <path
      d="M412.31 519.91h325.8l73.38 196.98c67.01-.1 2.19-.2 69.19-.31.09 59.5.18 119 .26 178.51h-165.4c6.83-63.89-24.75-125.23-79.24-154.63-74.24-40.06-148.52 2.06-152.36 4.33-23.88-74.96-47.76-149.92-71.63-224.88z"
      className="st5"
    />
    <path
      d="M811.49 717.89H472.08c-.55 0-1-.45-1-1s.45-1 1-1h339.41c.55 0 1 .45 1 1s-.45 1-1 1z"
      className="st0"
    />
    <path
      d="M810.38 874.67h271.13c5.64 0 10.21 4.57 10.21 10.21 0 5.64-4.57 10.21-10.21 10.21H810.38c-5.64 0-10.21-4.57-10.21-10.21 0-5.64 4.57-10.21 10.21-10.21z"
      className="st5"
    />
    <path
      d="m271.808 651.802 11.612-4.353 78.087 208.321-11.612 4.353z"
      className="st22"
    />
    <path
      d="M310.55 654.55h-60.13c-4.69 0-8.48-3.8-8.48-8.48 0-4.69 3.8-8.48 8.48-8.48h60.13c4.69 0 8.48 3.8 8.48 8.48 0 4.69-3.8 8.48-8.48 8.48z"
      className="st6"
    />
    <path
      d="m303.248 725.006 7.516 9.863-123.224 93.897-7.515-9.863zM349.6 859.881l-.031-12.4 219.13-.555.031 12.4z"
      className="st22"
    />
    <path
      d="M187.99 948.76c-69.97 0-126.9-56.93-126.9-126.9s56.93-126.9 126.9-126.9 126.9 56.93 126.9 126.9-56.93 126.9-126.9 126.9zm0-223.79c-53.43 0-96.9 43.47-96.9 96.9s43.47 96.9 96.9 96.9 96.9-43.47 96.9-96.9-43.47-96.9-96.9-96.9zM562.56 948.76c-51.25 0-92.94-41.69-92.94-92.94s41.69-92.94 92.94-92.94 92.94 41.69 92.94 92.94-41.7 92.94-92.94 92.94zm0-155.88c-34.71 0-62.94 28.23-62.94 62.94s28.24 62.94 62.94 62.94 62.94-28.24 62.94-62.94-28.24-62.94-62.94-62.94z"
      className="st6"
    />
    <circle cx={187.99} cy={821.86} r={13.74} className="st6" />
    <circle cx={562.56} cy={853.44} r={13.74} className="st6" />
    <path
      d="M287.57 730.53c-.82 11.52-1.63 23.03-2.45 34.55 19.55-.11 39.09-.21 58.64-.32l4.24-30.21-60.43-4.02z"
      className="st4"
    />
    <path
      d="m282.53 786.59 96.54-.05-31.06-18.83-.01-14.02-65.49.03z"
      className="st8"
    />
    <path
      d="M335.03 768.71c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM335.03 765.76c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM335.03 762.81c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM335.03 759.87c-.55 0-1-.45-1-1s.45-1 1-1l12.98-.01c.55 0 1 .45 1 1s-.45 1-1 1l-12.98.01z"
      className="st0"
    />
    <path d="m379.07 786.54-11.29-6.84-85.26.04.01 6.85z" className="st25" />
    <path
      d="M421.92 485.33c3.79 11.53 7.59 23.05 11.38 34.58h-20.99c1.91 20.96 3.82 41.93 5.72 62.89l-11.01 7.11c-16.74-5.43-33.48-10.85-50.22-16.28-21.58-28-17.25-65.4 4.57-83.25 15.8-12.92 39.62-14.69 60.55-5.05z"
      className="st4"
    />
    <path
      id="XMLID_00000152964526937820858850000010719960217037069493_"
      d="M249.33 562.38c-3.07 2.41-18.08 14.71-19.57 35.16-.27 3.72-1.37 18.81 8.56 29.66 6.55 7.15 15.21 9.53 32.4 11.54 15.42 1.8 36.74 3.17 62.67 1.44-20.89 35.11-41.78 70.23-62.66 105.34 31.45.37 62.91.74 94.36 1.1 25.83-36.26 51.65-72.52 77.48-108.78-9.32-25.83-18.65-51.67-27.97-77.5-55.1.67-110.18 1.35-165.27 2.04z"
      className="st26"
    />
    <path
      d="M253.61 588.11c17.67 4.26 45.39 8.67 78.88 4.59 39.83-4.85 69.51-19.48 86.22-29.35-10.02-8.67-25.87-19.48-47.24-23.85-64.54-13.18-115.34 45.59-117.86 48.61z"
      className="st27"
    />
    <path
      d="M294.67 745.79c26.21-47.06 52.42-94.12 78.63-141.17-57.83 21.87-109.63 28.22-139.77 15.55 1.21 2.43 2.77 4.82 4.79 7.03 6.55 7.15 15.21 9.53 32.4 11.54 15.42 1.8 36.74 3.17 62.67 1.44-20.89 35.11-41.78 70.23-62.66 105.34 7.98.08 15.96.18 23.94.27z"
      className="st29"
    />
    <path
      d="M245.07 601.59c-.48 0-.9-.34-.98-.83-.41-2.39-.26-5.03.45-7.85a1 1 0 0 1 1.94.48c-.64 2.55-.78 4.91-.42 7.02.09.54-.27 1.06-.82 1.16-.06.02-.12.02-.17.02zM292.38 619.58h-.01c-4.87-.06-9.44-.29-13.59-.66-.55-.05-.96-.54-.91-1.09.05-.55.53-.96 1.09-.91 4.1.37 8.62.59 13.43.66.55.01.99.46.99 1.01s-.45.99-1 .99zm13.53-.2c-.53 0-.98-.42-1-.96-.02-.55.41-1.02.96-1.04 4.26-.17 8.8-.43 13.47-.8a.997.997 0 1 1 .15 1.99c-4.7.36-9.26.63-13.55.8 0 .01-.01.01-.03.01zm26.99-2.05c-.5 0-.94-.38-.99-.89a1 1 0 0 1 .89-1.1c4.36-.47 8.87-1.02 13.41-1.63a.995.995 0 0 1 1.12.86.995.995 0 0 1-.86 1.12c-4.56.61-9.08 1.16-13.46 1.63-.04 0-.08.01-.11.01zm-67.39-.46c-.08 0-.15-.01-.23-.03-5.24-1.22-9.58-2.87-12.89-4.9a1.004 1.004 0 0 1 1.05-1.71c3.13 1.92 7.27 3.49 12.3 4.66a1 1 0 0 1 .75 1.2c-.12.47-.53.78-.98.78zM363.43 619.04a1 1 0 0 1-.87-1.49l2.64-4.71c-1.42.24-3.22.54-5.34.88-.54.09-1.06-.28-1.15-.83a.996.996 0 0 1 .83-1.14c3.33-.53 5.87-.97 7.38-1.23.38-.07.77.09.99.41.22.32.24.73.06 1.07l-3.67 6.54c-.18.31-.52.5-.87.5zM305.35 722.37a1 1 0 0 1-.87-1.49l7.26-12.92a1 1 0 0 1 1.74.98l-7.26 12.92c-.18.32-.52.51-.87.51zm14.52-25.84a1 1 0 0 1-.87-1.49l7.26-12.92a1 1 0 0 1 1.74.98l-7.26 12.92c-.18.33-.52.51-.87.51zm14.52-25.83a1 1 0 0 1-.87-1.49l7.26-12.92a1 1 0 0 1 1.74.98l-7.26 12.92c-.18.33-.52.51-.87.51zm14.52-25.83a1 1 0 0 1-.87-1.49l7.26-12.92a1 1 0 0 1 1.74.98l-7.26 12.92c-.18.32-.52.51-.87.51zM294.42 741.82a1 1 0 0 1-.87-1.49l3.67-6.54a1 1 0 0 1 1.74.98l-3.67 6.54c-.18.33-.52.51-.87.51z"
      className="st0"
    />
    <path
      d="M333.39 641.17c-.16 0-.33-.04-.48-.12a1.01 1.01 0 0 1-.4-1.36l6.43-11.75c.27-.48.87-.66 1.36-.4.48.26.66.87.4 1.36l-6.43 11.75c-.18.33-.52.52-.88.52z"
      className="st30"
    />
    <path
      d="m401.78 396.35-33.72-13.22-18.31 46.69c-2.4 66.79 2.63 77.55 6.48 77.91 3.56.33 10.59-7.85 25.06-57.11 1.57-5.36 3.4-10.65 5.44-15.85 5.01-12.81 10.03-25.62 15.05-38.42z"
      className="st4"
    />
    <path
      d="M403.11 392.96c-12.39 5.48-24.77 10.96-37.16 16.44-2.67-.74-5.34-1.47-8.02-2.21-2.13 6.01-4.25 12.01-6.38 18.02 2.26-3.06 4.53-6.12 6.79-9.18 9.88 5.17 19.76 10.35 29.64 15.52 5.04-12.87 10.09-25.73 15.13-38.59z"
      className="st31"
    />
    <path
      id="XMLID_00000010282139055299745390000002911807535589166775_"
      d="M398.81 370.13c8 1.12 14.13 7.68 14.7 15.74l1.24 17.57c.83 11.78-9.48 21.3-21.15 19.52l-38.24-5.82a.262.262 0 0 1-.22-.32l4.03-18.9c3.88-18.24 21.19-30.36 39.64-27.79z"
      className="st4"
    />
    <path
      d="M408.72 384.64c-6.41 6.45-18.18 7.17-29.8 2.15-.23 1.37-1.3 6.62-6.11 10.42-6.31 4.99-13.77 3.28-14.7 3.05l-.51-18.86 17.86-12.15 37.44 8.37c-1.12 3.43-2.81 5.64-4.18 7.02z"
      className="st31"
    />
    <path
      d="M414.5 375.69c.11.35.89 2.94-.59 5.42-5.82 9.74-36.31.16-39.79-.95.13.54 2.04 9.23-4.16 15.44-4.69 4.7-10.73 4.68-11.86 4.66-.39 3.6-.79 7.2-1.18 10.79.25.62 2.07 5.32.29 6.77-.93.76-2.45.31-2.82.2-7.51-2.21-12.43-35.35-3.84-39.88 1.75-.92 3.87-.31 5.06.15-.26-1.15-.44-2.82.21-4.5 5.09-12.99 54.46-11.36 58.68 1.9z"
      className="st32"
    />
    <path
      id="XMLID_00000080911358671516183470000014458591203849935490_"
      d="M368.25 401.23c-6.82-8.07-14.93-11.46-19.68-8.94-2.45 1.3-3.29 3.79-3.42 4.16-1.93 5.98 4.71 14.4 12.1 15.95 2.05.43 3.81.26 4.86.09"
      className="st4"
    />
    <path
      d="M396.4 413.2c-.92 0-2.03-.15-3.18-.63a8.26 8.26 0 0 1-3.49-2.73.996.996 0 0 1 .21-1.4.996.996 0 0 1 1.4.21c.48.64 1.33 1.52 2.65 2.07 1.63.68 3.12.48 3.87.31a.997.997 0 0 1 1.2.75 1 1 0 0 1-.75 1.2c-.46.11-1.12.22-1.91.22z"
      className="st33"
    />
    <path
      id="XMLID_00000180348656053129174740000004187121206763115684_"
      d="M268.31 508.79c-10.9-10.15-21.8-20.3-32.71-30.45a674.314 674.314 0 0 1 29.7-27.18c27.75-23.91 35.29-26.45 42.19-27.06 8.82-.78 20.82 1.22 25.91 2.06 6.84 1.14 12.45 2.55 16.34 3.64.35.62 7.24 12.59 20.37 12.91 8.36.21 14.18-4.43 15.77-5.79 3.4.6 8.12 1.63 13.56 3.45 5.79 1.94 11.36 3.81 15.59 6.88 6.39 4.64 7.71 10.53 14.68 33.48 1.57 5.15 4.08 13.36 7.34 23.62-16.71 4.23-33.42 8.47-50.13 12.7-.54 4.67-.85 9.89-.74 15.58.21 11 1.95 20.48 3.94 27.99-21.68 12.44-55.38 28.2-99.1 35.3-27.52 4.47-51.77 4.33-70.85 2.75 16.04-29.95 32.09-59.92 48.14-89.88z"
      style={{
        fill: "#dce6c3",
      }}
    />
    <path
      d="M386.27 535.23c0 .04-.01.07-.01.11-34.58 6.6-69.15 13.21-103.73 19.81 9.83-5.73 19.67-11.45 29.5-17.18 4.94-15.03 9.89-30.05 14.83-45.08L288 531.24c-14.52 10.39-29.04 20.79-43.57 31.18 16.96 7.85 41.49 16.7 72 19.72 8.45.84 16.61 1.14 24.48 1.05a297.398 297.398 0 0 0 49.21-22.56 117.85 117.85 0 0 1-3.85-25.4zM415.04 447.27c-4.23-3.07-9.8-4.94-15.59-6.88a93.495 93.495 0 0 0-9.94-2.73c15.45 9.3 21.39 19.84 23.69 24.8 2.43 5.23 3.94 11.02 5.5 16.97 2.9 11.08 4.33 20.79 5.06 28.31 4.43-1.12 8.86-2.24 13.29-3.37-3.26-10.26-5.77-18.46-7.34-23.62-6.96-22.95-8.28-28.85-14.67-33.48zM389.33 493.12c-.58 7.92-1.15 15.85-1.73 23.77 3.98-1.01 7.96-2.02 11.95-3.03-3.41-6.91-6.81-13.83-10.22-20.74z"
      className="st35"
    />
    <path
      d="M293.81 527.2c-.25 0-.51-.1-.7-.29-.39-.39-.39-1.02-.01-1.41l33.05-33.3c.39-.39 1.02-.39 1.41-.01.39.39.39 1.02.01 1.41l-33.05 33.3c-.2.2-.45.3-.71.3zM386.92 518.06c-.04 0-.09 0-.13-.01a.995.995 0 0 1-.86-1.12c.8-6.03 1.46-12.14 1.97-18.18.44-5.21.78-10.5 1.01-15.72.02-.55.51-.96 1.04-.96.55.02.98.49.96 1.04-.23 5.25-.57 10.56-1.01 15.8-.51 6.07-1.18 12.22-1.98 18.28-.08.51-.51.87-1 .87z"
      className="st36"
    />
    <path
      id="XMLID_00000039834120717524098720000002737662447010692993_"
      d="M240.21 482.63c-3.99 3.82-27.6 27.02-22.66 54.8.76 4.3 2.6 14.61 10.53 22.1 33.03 31.21 158.68 1.07 158.72 1.1 0 0 .01.01.02.01.01.01.02.01.04.02.11.06.35.19.65.35 1 .54 2.92 1.57 5.36 2.8 14.78 7.41 17.97 6.61 19.49 5.55 1.1-.76 1.69-1.82 2.03-2.59-4.18-15.1-8.36-30.2-12.54-45.31a366.691 366.691 0 0 0-40.42-5.95c-31.96-2.9-60.31-1.23-83.6 1.97-12.55-11.62-25.09-23.24-37.62-34.85z"
      className="st4"
    />
    <path
      d="M425.56 566c-.22 0-.44-.07-.62-.21l-12.05-9.51a1 1 0 0 1-.17-1.4 1 1 0 0 1 1.4-.17l12.05 9.51a1 1 0 0 1 .17 1.4c-.19.25-.49.38-.78.38z"
      className="st0"
    />
    <path
      d="M414.38 567.76a.96.96 0 0 1-.58-.19l-12.06-8.66c-.45-.32-.55-.95-.23-1.4.32-.45.95-.55 1.4-.23l12.06 8.66a1.006 1.006 0 0 1-.59 1.82zM251.26 522.25c-.46 0-.87-.31-.97-.77a1 1 0 0 1 .75-1.2c4.4-1.02 8.89-1.85 13.35-2.49 4.42-.63 8.92-1.07 13.37-1.31.55-.02 1.02.39 1.05.94s-.39 1.02-.94 1.05c-4.4.24-8.84.68-13.2 1.3-4.41.62-8.84 1.45-13.18 2.46-.08.01-.15.02-.23.02z"
      className="st33"
    />
    <path
      d="M312.22 676.77a.96.96 0 0 1-.51-.14c-.47-.28-.63-.9-.35-1.37l20.24-34.02-22.47.73c-.58.05-1.01-.42-1.03-.97-.02-.55.42-1.01.97-1.03l24.3-.79c.37-.02.71.18.89.49.19.32.19.71 0 1.02l-21.17 35.59c-.2.31-.53.49-.87.49zM365.09 747.62c-.2 0-.4-.06-.58-.18a.996.996 0 0 1-.24-1.39l64.85-91.55c.32-.45.94-.56 1.39-.24.45.32.56.94.24 1.39L365.9 747.2c-.19.27-.5.42-.81.42z"
      className="st30"
    />
    <path
      d="M344.44 566.21c-38.76 2.29-83.71 4.95-112.85-23.05-3.95-3.8-9.38-9.86-14.36-19.06-.52 4.27-.5 8.73.32 13.32.76 4.3 2.6 14.61 10.53 22.1 33.03 31.21 158.68 1.07 158.72 1.1-.19-.84-13.49 3.88-42.36 5.59z"
      className="st37"
    />
    <path
      d="M277.82 517.47c-12.54-11.61-25.07-23.23-37.61-34.84-1.31 1.25-4.74 4.6-8.57 9.51l46.18 25.33z"
      className="st31"
    />
    <path
      d="M359.75 393.88c-4.26-2.48-8.33-3.11-11.18-1.6-2.45 1.3-3.29 3.79-3.42 4.16-1.7 5.27 3.25 12.42 9.5 15.11-1.45-3.92-5.58-9.86-3.37-13.89 1.38-2.48 4.76-3.47 8.47-3.78z"
      className="st37"
    />
    <path
      d="M340.29 427.48c-2.12-.45-4.42-.9-6.88-1.31-5.09-.85-17.09-2.84-25.91-2.06-6.9.61-14.44 3.15-42.19 27.06a680.09 680.09 0 0 0-29.7 27.18c3.57 3.32 7.14 6.65 10.71 9.97 9.38-7.88 17.45-15.08 24.15-21.28 18.89-17.46 24.72-24.85 37.26-31.18 8.44-4.28 19.11-7.64 32.56-8.38z"
      style={{
        fill: "#e8f0d6",
      }}
    />
    <path
      d="M913.77 540.13c-.25-4.51-.23-11.08 1.14-19.12 0 0 .15-.89.33-1.83 4.29-21.79 13.23-36 11.62-36.83-.88-.45-4.35 3.4-15.25 21.17 15.92-40.66 14.13-43.77-7.22-5.37 14.83-41.97 12.76-44.56-7.51-4.71 15.15-41.57 7.57-34.18-14.01 3.17 1.12-6.25 2.33-14.69.6-15.25-2.17-.71-7.87 11.28-8.47 12.56-1.1 2.34-4.72 10.25-7.74 25.06a176.27 176.27 0 0 0-2.7 17.94l49.21 3.21z"
      className="st12"
    />
    <path
      d="M900.97 515.45c-.03 0-.07 0-.1-.01a1 1 0 0 1-.89-1.1c.38-3.73-.21-6.3-1.77-7.65-1.78-1.55-4.65-1.22-7.43-.9-3.02.35-6.15.71-8.08-1.39-1.58-1.73-1.82-4.5-.72-8.48.15-.53.7-.84 1.23-.7.53.15.84.7.7 1.23-.89 3.22-.8 5.44.27 6.6 1.24 1.35 3.74 1.06 6.38.76 3.05-.35 6.52-.75 8.97 1.37 2.07 1.8 2.89 4.95 2.44 9.36-.06.53-.49.91-1 .91zM914.26 505.61c-.22 0-.43-.07-.62-.21-4.18-3.28-8.72-6.1-13.49-8.37a72.664 72.664 0 0 0-8.13-3.28c-.52-.17-.81-.74-.63-1.27s.74-.81 1.26-.63c2.85.95 5.66 2.08 8.36 3.37 4.9 2.33 9.57 5.23 13.87 8.6a1 1 0 0 1 .17 1.4c-.2.26-.5.39-.79.39z"
      className="st39"
    />
    <path
      d="m757.54 508.5-34.75 9.36c2.54 9.7 5.09 19.41 7.63 29.11 6.01 14.45 19.83 19.5 27.34 15.47 5.03-2.69 7.61-9.66 7.5-16.93-2.57-12.33-5.14-24.67-7.72-37.01z"
      className="st12"
    />
    <path
      id="XMLID_00000018937665828605838460000017795275830957197186_"
      d="M640.18 658.47c7.08-60.26 34.54-99.02 48.43-104.79 13.67-5.68 27.99-6.5 27.99-6.5 3.49-.2 6.43-.15 8.53-.05-.23-2.59-.45-5.18-.68-7.77l43.82-1.47 1.08 7.41c2.75 0 6.68.11 11.38.62 0 0 9.77 1.06 18.4 3.64 10.81 3.24 30.7 17.09 49.71 41.04 3.36-22.17 6.73-44.33 10.09-66.5 19.27 2.81 38.55 5.63 57.82 8.44-.8 11.72-2.22 29.25-4.86 50.49-7.39 59.64-14.24 67.48-18.34 71.08-21.54 18.92-62.1 4.55-72.23.69l.69 10.44-120.71.12c-20.37-2.3-40.75-4.6-61.12-6.89z"
      className="st26"
    />
    <path
      d="M874.18 526.32c-5.08-.74-10.17-1.48-15.25-2.23-2.2 14.48-4.4 28.97-6.59 43.45 7.28-13.73 14.56-27.47 21.84-41.22z"
      className="st29"
    />
    <path
      d="M767.24 551.27c7.48-.44 16.59-.26 26.75 1.53 8.64 1.52 16.36 3.91 23.15 6.65-7.14-5.2-13.4-8.52-18.01-9.9-8.63-2.58-18.4-3.64-18.4-3.64-4.7-.51-8.64-.62-11.38-.62l-1.08-7.41c-1.33.04-2.65.09-3.98.13.98 4.42 1.97 8.84 2.95 13.26zM821.32 654.8c4.98 1.9 17.35 6.34 31.18 8.11-10.53-21.33-21.06-42.66-31.58-63.99-.01 22.1-.02 44.21-.02 66.31h1.11l-.69-10.43zM891.06 629.38c-2.43 10.86-5.15 21.2-8.06 31.03 3.82-1.48 7.39-3.52 10.55-6.3 4.1-3.6 10.96-11.44 18.34-71.08a924 924 0 0 0 4.86-50.49c-4.75-.69-9.5-1.39-14.26-2.08-.32 28.96-3.28 62.46-11.43 98.92z"
      className="st27"
    />
    <path
      d="M733.96 553.34c.64-4.79 1.27-9.59 1.91-14.38-3.81.13-7.62.26-11.43.38.23 2.59.45 5.18.68 7.77-2.1-.09-5.04-.15-8.53.05 0 0-14.32.83-27.99 6.5-7.67 3.18-19.47 16.44-29.69 37.81 8.33-12.25 20.67-25.91 39.08-33.19 13.18-5.19 25.91-5.73 35.97-4.94z"
      className="st29"
    />
    <path
      d="M821.32 655.8c-.55 0-1-.44-1-.99l-.4-55.87c0-.55.44-1 .99-1.01h.01c.55 0 1 .44 1 .99l.4 55.88c.01.54-.44.99-1 1 .01 0 .01 0 0 0zM706.7 645.97c-.13 0-.26-.03-.39-.08a.995.995 0 0 1-.53-1.31l13.86-32.67c.22-.51.8-.74 1.31-.53.51.22.75.8.53 1.31l-13.86 32.67c-.16.38-.53.61-.92.61zM847.24 601.23c-.05 0-.11 0-.17-.01-.54-.09-.91-.61-.82-1.15l1.61-9.63c.09-.54.61-.91 1.15-.82.54.09.91.61.82 1.15l-1.61 9.63c-.08.48-.5.83-.98.83z"
      className="st30"
    />
    <path
      d="M761.27 526.56c-1.68-1.36-13.66-10.7-27.4-6.9-4.11 1.14-7.29 3.15-9.54 4.98l3.75 13.36c1.49-3.83 2.98-7.67 4.47-11.5 10.26 3.35 20.52 6.71 30.78 10.06-.68-3.33-1.37-6.67-2.06-10z"
      className="st40"
    />
    <path
      id="XMLID_00000104708257992221933950000010781654586680628662_"
      d="M761.23 476.56c8.08-.1 15.13 5.46 16.91 13.34l3.88 17.18c2.6 11.52-6.15 22.48-17.96 22.49-12.89.01-25.79.01-38.68.02-.16 0-.28-.13-.27-.29l1.13-19.29c1.09-18.62 16.36-33.21 34.99-33.45z"
      className="st12"
    />
    <path
      d="M774.95 483.15c-2.93-1.67-6.97-2.1-8.12-2.2l-36.43 14.32c-.74 1.38-1.39 2.82-1.95 4.3.62 5.13 1.23 10.26 1.85 15.39.58.16 3.48.89 6.19-.79 2.69-1.66 4.09-5.03 3.5-8.48 5.07.97 15.39 2.08 25.19-3.46 4.56-2.58 11.59-8.07 12.81-12.94-.61-2.28-1.65-4.36-3.04-6.14z"
      className="st40"
    />
    <path
      id="XMLID_00000036968225795267827980000001064840884275260856_"
      d="M781.16 479.39c1.38 4.06-.43 8-.87 8.97-4.99 10.87-23.3 15.69-45.64 12.58.24.26 3.1 3.47 2.07 7.75-.75 3.1-3.24 5.53-6.42 6.27.52.67 3.54 4.68 2.67 10.23-.55 3.56-2.66 7.52-6.12 9.23-6.44 3.18-15.73-2.34-19.48-7.84-6.26-9.17-2.17-25.73 11.3-40.28-5.58 7.85-15.11 11.26-23.23 8.67-10.76-3.43-15.39-16.26-12.61-26.14 3.48-12.37 18.57-19.96 28.92-14.95 9.76 4.72 14.64 20.31 6.39 33.3 2.6-3.96 8.07-11.07 17.43-15.36 17.36-7.97 41.86-3.36 45.59 7.57z"
      className="st0"
    />
    <path
      d="M780.29 488.36c.27-.59 1.05-2.29 1.3-4.44-4.91-10.76-29.45-11.43-30.63-11.45-12.77-.23-22.32 4.6-26.63 7.15v-.01a45.596 45.596 0 0 0-6.19 7.56c.18-.29.35-.58.53-.88 7.49-12.8 2.63-27.8-6.92-32.42-5.35-2.59-11.97-1.8-17.59 1.26-4.55 7.42-6.5 19.27.16 27.03 5.05 5.89 13.88 8.06 22.77 6.11-9.43 15.26-11.52 30.04-5.02 37.84 3.33 3.99 8.04 5.12 10.07 5.58 2.39.54 4.9.72 7.46.61 1.83-1.97 2.99-4.63 3.37-7.11.86-5.55-2.15-9.56-2.67-10.23 3.18-.74 5.68-3.17 6.42-6.27 1.03-4.28-1.84-7.5-2.07-7.75 22.33 3.12 40.65-1.71 45.64-12.58z"
      style={{
        fill: "#ebebeb",
      }}
    />
    <path
      id="XMLID_00000098913552763435029400000003577368868106147481_"
      d="M737.47 513.75c-4.14-4.95-12.75-8.46-17.44-4.9-2.25 1.7-2.7 4.35-2.75 4.63-1 6.41 7.39 13.01 14.36 13.94 2.71.36 4.15-.27 4.82-.65"
      className="st12"
    />
    <path
      d="M720.03 508.84c-2.25 1.7-2.7 4.35-2.75 4.63-.71 4.52 3.28 9.14 8.13 11.79-3.24-5.26-2.83-11.09.09-13.9 1.82-1.75 4.37-2.1 6.26-2.08-4.04-2.07-8.7-2.73-11.73-.44z"
      style={{
        fill: "#f9c5a7",
      }}
    />
    <path
      d="M764.07 519.59c-.61 0-1.27-.07-1.96-.24a8.357 8.357 0 0 1-3.86-2.17.996.996 0 1 1 1.4-1.42c.57.56 1.54 1.31 2.94 1.65 1.71.42 3.16.01 3.87-.28.51-.21 1.1.05 1.3.56.21.51-.05 1.09-.56 1.3-.64.26-1.76.6-3.13.6z"
      className="st39"
    />
    <path d="M911.69 821.4v28.5h54.15v-26.67z" className="st44" />
    <path
      d="m903.54 874.67 96.54-.04-31.06-18.84-.01-14.01-65.49.03z"
      className="st8"
    />
    <path
      d="M956.04 856.8c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM956.04 853.85c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM956.04 850.9c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM956.04 847.95c-.55 0-1-.45-1-1s.45-1 1-1l12.98-.01c.55 0 1 .45 1 1s-.45 1-1 1l-12.98.01z"
      className="st0"
    />
    <path d="m1000.08 874.63-11.28-6.84-85.27.04.01 6.84z" className="st7" />
    <path
      id="XMLID_00000056401087728690152860000018418502808621865909_"
      d="M889.1 641.76H783.61v86.8h100.43V833.8l88.22 1.22V724.93c.01-45.94-37.23-83.17-83.16-83.17z"
      className="st2"
    />
    <path
      d="M972.27 835.01V724.92c0-9.64-1.66-18.88-4.67-27.49L892.74 833.9l79.53 1.11z"
      style={{
        fill: "#f6e4c2",
      }}
    />
    <path d="M854.22 821.4v28.5h54.15v-26.67z" className="st44" />
    <path
      d="m849.07 874.67 96.54-.04-31.06-18.84-.01-14.01-65.49.03z"
      style={{
        fill: "#c2edc4",
      }}
    />
    <path
      d="M901.57 856.8c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM901.57 853.85c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM901.57 850.9c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM901.57 847.95c-.55 0-1-.45-1-1s.45-1 1-1l12.98-.01c.55 0 1 .45 1 1s-.45 1-1 1l-12.98.01zM945.61 874.63l-11.29-6.84-85.26.04.01 6.84z"
      className="st0"
    />
    <path
      id="XMLID_00000160895938233052435460000017499833975446724504_"
      d="M834.63 641.76H729.14v86.8h100.43V833.8l88.22 1.22V724.93c.01-45.94-37.23-83.17-83.16-83.17z"
      className="st3"
    />
    <path
      d="M917.8 835.01V724.92c0-2.67-.14-5.3-.38-7.9l-76.13 116.93 76.51 1.06z"
      style={{
        fill: "#faecd2",
      }}
    />
    <path
      d="M649.38 664.24c3.07 15.05 9.45 22.49 14.27 26.44 28.92 23.7 85.6-6.86 136.33-6.67 18.69.07 33.95 1.54 43.73 2.74 8.14 1.06 15.52 2.35 15.92 1.07.32-1.02-4.62-3.98-23.43-11.5 42.78 8.9 45.54 6.61 4.09-8.02 43.88 7.6 46.09 5.13 3.39-8.2 43.54 7.98 34.98 1.74-5.47-13.29 6.35.06 14.88-.16 15.15-1.96.09-.61-.65-2.24-13.81-6.25-24.63-7.5-44.09-8.72-44.09-8.72-35.36-2.21-72.67 2.57-84.15 4.16"
      className="st12"
    />
    <path
      d="m816.31 630.3 3.21 60.99c-60.27 51.83-135.88 44.41-163.72 7.34-26.13-34.8-4.76-88.56-2.97-92.9 19.49 9.44 38.99 18.87 58.48 28.31 14.01-2.22 29.56-3.98 46.45-4.81 21.59-1.07 41.26-.42 58.55 1.07z"
      className="st26"
    />
    <path
      d="M689.57 644.11c-.39 0-.76-.23-.92-.62-.21-.51.03-1.09.54-1.31l21.74-9.07c.08-.03.16-.05.24-.07a493.41 493.41 0 0 1 42.22-4.24c20.9-1.18 42.09-1.02 63 .49.55.04.96.52.92 1.07s-.53.97-1.07.92c-20.81-1.5-41.92-1.66-62.74-.48a490.41 490.41 0 0 0-41.92 4.2l-21.62 9.02c-.13.07-.26.09-.39.09z"
      className="st30"
    />
    <path d="M785.21 821.4v28.5h54.15v-26.67z" className="st11" />
    <path
      d="m778.06 874.67 96.54-.04-31.06-18.84-.01-14.01-65.49.03z"
      className="st48"
    />
    <path
      d="M830.56 856.8c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM830.56 853.85c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM830.56 850.9c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM830.56 847.95c-.55 0-1-.45-1-1s.45-1 1-1l12.98-.01c.55 0 1 .45 1 1s-.45 1-1 1l-12.98.01z"
      className="st0"
    />
    <path d="m874.6 874.63-11.28-6.84-85.27.04.01 6.84z" className="st7" />
    <path
      d="M763.62 641.76H658.13v86.8h100.43V833.8l88.22 1.22V724.93c.01-45.94-37.23-83.17-83.16-83.17z"
      className="st6"
    />
    <path d="M730.74 821.4v28.5h54.15v-26.67z" className="st11" />
    <path
      d="m723.59 874.67 96.54-.04-31.06-18.84-.01-14.01-65.49.03z"
      className="st48"
    />
    <path
      d="M776.09 856.8c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM776.09 853.85c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM776.09 850.9c-.55 0-1-.45-1-1s.45-1 1-1h12.98c.55 0 1 .45 1 1s-.45 1-1 1h-12.98zM776.09 847.95c-.55 0-1-.45-1-1s.45-1 1-1l12.98-.01c.55 0 1 .45 1 1s-.45 1-1 1l-12.98.01z"
      className="st0"
    />
    <path
      d="m820.13 874.63-11.29-6.84-85.26.04.01 6.84zM709.15 641.76H603.66v86.8h100.43V833.8l88.22 1.22V724.93c.01-45.94-37.23-83.17-83.16-83.17z"
      className="st7"
    />
    <path
      d="M846.79 819.35H704.1c-.55 0-1-.45-1-1s.45-1 1-1h142.69c.55 0 1 .45 1 1s-.45 1-1 1z"
      className="st0"
    />
    <path
      d="M665.96 895.09h162.3c5.6 0 10.14-4.54 10.14-10.14 0-5.57-4.5-10.1-10.08-10.14-9.93-.06-16.33-.12-29.25-.18.37-14.32 1.29-112.75-76.41-180.41-67.66-58.9-167.87-70.96-254.58-30.42-18.59-47.96-37.18-95.93-55.77-143.89-1.93-4.98-7.4-7.59-12.48-5.97l-.19.06c-5.49 1.75-8.41 7.73-6.41 13.14 31.23 84.13 62.47 168.26 93.7 252.39 35.76-33.99 89.3-40.66 130.7-16.97 44.96 25.72 65.9 81.68 48.33 132.53z"
      className="st28"
    />
    <path
      d="M341.65 798.47h-42.33c-3.3 0-5.97-2.67-5.97-5.97s2.67-5.97 5.97-5.97h42.33c3.3 0 5.97 2.67 5.97 5.97.01 3.3-2.67 5.97-5.97 5.97z"
      className="st22"
    />
    <circle cx={351.44} cy={853.18} r={25.75} className="st6" />
    <path
      d="M660.08 627.39c1.88 4.79 3.76 9.57 5.64 14.36-11.87.16-23.73.31-35.6.47-1.83-10.45-3.67-20.89-5.5-31.34l35.46 16.51z"
      className="st11"
    />
    <path
      d="M597.73 545.28c-13.98.62-27.96 1.25-41.94 1.87-.37 3.89-3.71 45.21 24.36 64.54 2.07 1.43 18.66 12.85 27.21 7.03 11.81-8.04 1.2-44.22-9.63-73.44z"
      className="st0"
    />
    <path
      id="XMLID_00000047036887089565728460000011571872461424034982_"
      d="M530.84 637.36c-20.56-3.05-41.12-6.11-61.68-9.16 8.66-39.86 24.42-70.07 40.67-75.56 6.44-2.18 14.84-3.69 14.84-3.69 7.04-1.01 14.75-1.71 23.03-1.84 2.77-.05 5.46-.03 8.08.05a144.08 144.08 0 0 0 9.38 24.49c9.21 18.74 20.88 32.16 30.04 40.95 2.94-14.62 4.87-32.07 3.9-51.65-.27-5.46-.75-10.68-1.38-15.66.98.09 2.32.24 3.91.46 2.04.28 6.82 1 12.94 2.7 0 0 8.82 2.44 15.9 5.7 10.44 4.82 26.42 34.71 35.17 77.93-7.26-.28-14.52-.56-21.78-.85l.36 10.82c-15.33.41-30.66.81-45.99 1.22.38 6.99.77 13.99 1.15 20.98-22.92.37-45.85.75-68.77 1.12.09-9.34.16-18.68.23-28.01z"
      className="st48"
    />
    <path
      d="M492.31 597.74c5.41-13.36 8.66-21.22 15.82-29.04 15.21-16.63 36.45-20.47 47.66-21.54-2.62-.07-5.32-.09-8.08-.05-8.28.14-15.99.83-23.03 1.84 0 0-8.4 1.51-14.84 3.69-16.26 5.49-32.01 35.7-40.67 75.56l12.91 1.92c2.06-8.73 5.08-19.66 10.23-32.38z"
      style={{
        fill: "#ffda8f",
      }}
    />
    <path
      d="M630.48 554.14c-7.08-3.27-15.9-5.7-15.9-5.7-6.13-1.69-10.9-2.41-12.94-2.7-1.59-.22-2.93-.36-3.91-.46.06.48.11.97.17 1.45 23.69 5.67 33.44 17.5 36.81 22.27 4.4 6.23 6.76 13.61 11.31 28.13 4.25 13.54 7.13 25.36 9.08 34.52 3.52.14 7.04.27 10.56.41-8.76-43.21-24.74-73.1-35.18-77.92zM595.47 659.77c-.55 0-1-.44-1-.99l-.27-46.18c0-.55.44-1 .99-1.01h.01c.55 0 1 .44 1 .99l.27 46.18c0 .56-.44 1.01-1 1.01z"
      className="st50"
    />
    <path
      d="M644.35 642.5h-.04c-.55-.02-.98-.49-.96-1.04.48-12.62-.74-25.18-3.64-37.33a138.31 138.31 0 0 0-6.07-19.12.994.994 0 0 1 .56-1.3c.52-.2 1.09.04 1.3.56 2.52 6.3 4.59 12.82 6.16 19.4 2.94 12.33 4.18 25.07 3.7 37.88-.03.53-.48.95-1.01.95zM540.55 640.05a.96.96 0 0 1-.33-.06c-.52-.18-.8-.75-.62-1.27l9.33-26.75c.18-.52.75-.8 1.27-.62.52.18.8.75.62 1.27l-9.33 26.75a.98.98 0 0 1-.94.68z"
      className="st50"
    />
    <path
      d="m586.86 512.49-34.75 9.36c2.54 9.7 5.09 19.41 7.63 29.11 6.01 14.45 19.83 19.5 27.34 15.47 5.03-2.69 7.61-9.66 7.5-16.93-2.57-12.33-5.14-24.67-7.72-37.01z"
      className="st11"
    />
    <path
      id="XMLID_00000098217224567418389280000000005534257960742037_"
      d="M489.11 697.22c23.42 18.67 59.15-3.6 136.49 1.09a369.72 369.72 0 0 1 43.51 5.23c8.07 1.52 15.36 3.23 15.83 1.97.38-1-4.39-4.24-22.74-12.81 42.2 11.31 45.09 9.19 4.54-7.78 43.37 10.08 45.73 7.74 3.85-7.99 43.02 10.45 34.83 3.73-4.71-13.58 6.34.42 14.86.69 15.23-1.09.13-.61-.52-2.28-13.43-7.03-24.17-8.89-43.52-11.21-43.52-11.21-35.17-4.22-72.7-1.56-84.25-.64l.63-4.33c-22.28-3.39-44.55-6.78-66.83-10.18-4.8 42.97 5.39 60.37 15.4 68.35z"
      className="st11"
    />
    <path
      d="M589.76 525.74c-8.79-.19-17.58-.39-26.37-.58-2.01-.04-4.02-.09-6.02-.13l-3.72 3.66c1.29 4.57 2.57 9.13 3.86 13.7 1.79-5.13 3.57-10.27 5.36-15.4 9.73 3.47 19.47 6.95 29.2 10.42-.78-3.89-1.55-7.78-2.31-11.67z"
      className="st32"
    />
    <path
      id="XMLID_00000023254067800467824960000011985143254638320554_"
      d="M590.55 476.56c8.08-.1 15.13 5.46 16.91 13.34l3.88 17.18c2.6 11.52-6.15 22.48-17.96 22.49-12.89.01-25.79.01-38.68.02-.16 0-.28-.13-.27-.29l1.13-19.29c1.09-18.62 16.36-33.21 34.99-33.45z"
      className="st11"
    />
    <path
      d="m608.23 493.28-.77-3.39c-.94-4.16-3.35-7.66-6.61-10.03-3.71-.28-7.7-.13-11.1.33-12.89 1.74-30.07 9.67-31.18 21.25-.55 5.69 2.6 12.64 5.35 12.69 1.94.03 3.58-3.36 4.59-5.96.51.16 4.89 1.43 8.87-1.38 4.32-3.04 4.29-8.15 4.28-8.56.42.3 4.34 3.05 9.17 1.53 5.34-1.68 6.8-7.01 6.88-7.34.32.23 4.72 3.3 9.48 1.38.35-.16.7-.33 1.04-.52z"
      className="st32"
    />
    <path
      id="XMLID_00000114067517446035238950000008081479940131374224_"
      d="M606.09 469.26c1.93 2.01 2.23 5.64.46 8.71 3.92 2.82 4.82 7.69 3.06 10.39-2.02 3.1-8.02 4.1-12.23.15-.44 1.1-1.99 4.56-5.81 6.57-6.06 3.2-12.24-.2-12.69-.46.31 4.03-1.99 7.71-5.5 9.02-2.85 1.06-6.13.41-8.56-1.63.33.5 2.05 3.25 1.22 6.67-.74 3.04-3.22 5.45-6.42 6.27l2.67 10.23c1.29 4.1-.53 8.4-3.9 10.1-3.99 2.02-9.51.06-11.46-4.74-.37.04-6.72.62-10.24-3.97-3.22-4.2-2.7-10.93 1.83-15.44-2.73-3.59-3.05-8.3-.88-11.75 2.25-3.58 6.25-4.27 6.92-4.38-2.43-3.89-1.76-8.92 1.45-11.85 2.85-2.6 7.19-3.09 10.73-1.16-.43-4.38 1.74-8.57 5.47-10.61 4.18-2.28 9.55-1.44 12.99 2.14-.29-5.37 3.7-10.01 8.87-10.55 4.9-.51 9.57 2.83 10.7 7.8 3.19-4.07 8.74-4.2 11.32-1.51z"
      className="st0"
    />
    <path
      d="M609.6 488.36c1.76-2.7.86-7.57-3.06-10.39 1.77-3.07 1.47-6.7-.46-8.71-2.57-2.68-8.13-2.56-11.31 1.53a10.13 10.13 0 0 0-3.24-5.4c-4.25-.31-8.3 1.09-11.01 3.99-2.45 2.62-3.08 5.69-3.27 7.15-4.52-3.04-10.32-2.9-14.1.15-4.69 3.78-5.6 11.48-1.22 16.97-2.59-1.34-5.64-.99-7.49.76-2.39 2.27-2.5 6.63.31 9.48-3.99-2.12-8.72-1.24-11.16 1.68-3.04 3.63-2.26 10.07 2.6 13.3-2.53.72-4.49 2.65-5.04 5.04-.53 2.26.26 4.69 1.98 6.42 2.01.39 3.62.25 3.8.23 1.96 4.8 7.47 6.76 11.46 4.74 3.37-1.7 5.18-6 3.9-10.1l-2.67-10.23c3.2-.82 5.68-3.23 6.42-6.27.83-3.42-.9-6.16-1.22-6.67 2.43 2.04 5.71 2.69 8.56 1.63 3.51-1.31 5.82-4.99 5.5-9.02.45.26 6.63 3.66 12.69.46 3.81-2.01 5.37-5.47 5.81-6.57 4.21 3.93 10.21 2.93 12.22-.17z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      id="XMLID_00000165931432922228504640000010149778508871783552_"
      d="M566.79 513.75c-4.14-4.95-12.75-8.46-17.44-4.9-2.25 1.7-2.7 4.35-2.75 4.63-1 6.41 7.39 13.01 14.36 13.94 2.71.36 4.15-.27 4.82-.65"
      className="st11"
    />
    <path
      d="M549.35 508.84c-2.25 1.7-2.7 4.35-2.75 4.63-.52 3.32 1.49 6.68 4.51 9.29-.31-.82-.55-1.73-.67-2.76-.1-.88-.48-4.1 1.26-6.54 2.09-2.93 6.76-4.03 11.76-2.74-4.45-3.12-10.46-4.64-14.11-1.88z"
      className="st52"
    />
    <path
      d="M593.39 519.59c-.61 0-1.27-.07-1.96-.24a8.357 8.357 0 0 1-3.86-2.17.996.996 0 1 1 1.4-1.42c.57.56 1.54 1.31 2.94 1.65 1.71.42 3.16.01 3.87-.28.51-.21 1.09.05 1.3.56.2.51-.05 1.09-.56 1.3-.64.26-1.76.6-3.13.6zM517.7 652.21c-.36 0-.71-.2-.89-.54-.25-.49-.06-1.09.43-1.35 4.64-2.38 9.5-4.36 14.46-5.88 2.62-.8 5.32-1.49 8.02-2.04.54-.11 1.07.24 1.18.78.11.54-.24 1.07-.78 1.18-2.64.54-5.27 1.21-7.83 1.99-4.84 1.48-9.6 3.41-14.13 5.74-.15.08-.31.12-.46.12z"
      className="st53"
    />
    <path
      d="M623.57 698.2c-16.18-4.84-38.08-8.77-60.11-8.74-32.44.04-47.38 8.66-65.12-2.45-16.56-10.37-21.26-28.97-25.85-37.2.11 28.01 8.42 40.87 16.62 47.4 23.22 18.51 58.53-3.2 134.46.99z"
      className="st52"
    />
    <path
      d="m540.16 641.74.39-2.69c-22.28-3.39-44.55-6.78-66.83-10.18-.5 4.5-.83 8.7-1.03 12.66l67.47.21z"
      className="st32"
    />
  </svg>
);
export default Hero;
